var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sign-in"},[_c('a-row',{staticClass:"row-main",attrs:{"type":"flex","gutter":[24, 24],"justify":"space-around","align":"middle"}},[_c('a-col',{staticClass:"col-form mr-auto",attrs:{"span":24,"md":{ span: 14, offset: 2 },"lg":10,"xl":6}},[_c('h4',{staticClass:"mb-15"},[_vm._v(_vm._s(_vm.$t('Authentication.Login')))]),_c('p',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t('Authentication.UsingUsernamePasswordLogin')))]),_c('a-tabs',{attrs:{"default-active-key":1,"activeKey":_vm.loginTypeActiveKey},on:{"change":function($event){return _vm.loginTypeChange($event)}}},[_c('a-tab-pane',{key:1,attrs:{"tab":_vm.$t('Authentication.LocalAuthentication')}},[_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form,"hideRequiredMark":true},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":_vm.$t('Authentication.Username'),"colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
									'username',
									{ rules: [{ required: true, message: _vm.$t('Authentication.EnterYourUsername') }] } ]),expression:"[\n\t\t\t\t\t\t\t\t\t'username',\n\t\t\t\t\t\t\t\t\t{ rules: [{ required: true, message: $t('Authentication.EnterYourUsername') }] },\n\t\t\t\t\t\t\t\t]"}],attrs:{"placeholder":_vm.$t('Authentication.EnterYourUsername')}})],1),_c('a-form-item',{staticClass:"mb-5",attrs:{"label":_vm.$t('Authentication.Password'),"colon":false}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
									'password',
									{ rules: [{ required: true, message: _vm.$t('Authentication.EnterThePassword')}] } ]),expression:"[\n\t\t\t\t\t\t\t\t\t'password',\n\t\t\t\t\t\t\t\t\t{ rules: [{ required: true, message: $t('Authentication.EnterThePassword')}] },\n\t\t\t\t\t\t\t\t]"}],attrs:{"type":"password","placeholder":_vm.$t('Authentication.EnterThePassword')}})],1),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","block":"","html-type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('Authentication.Login'))+" ")])],1)],1)],1),_c('a-tab-pane',{key:2,attrs:{"tab":'LDAP' + _vm.$t('Authentication.Authentication')}},[_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form,"hideRequiredMark":true},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":'LDAP' + _vm.$t('Authentication.Username'),"colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
									'username',
									{ rules: [{ required: true, message: _vm.$t('Authentication.enterLDAPUsername') }] } ]),expression:"[\n\t\t\t\t\t\t\t\t\t'username',\n\t\t\t\t\t\t\t\t\t{ rules: [{ required: true, message: $t('Authentication.enterLDAPUsername') }] },\n\t\t\t\t\t\t\t\t]"}],attrs:{"placeholder":_vm.$t('Authentication.enterLDAPUsername')}})],1),_c('a-form-item',{staticClass:"mb-5",attrs:{"label":_vm.$t('Authentication.Password'),"colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
									'password',
									{ rules: [{ required: true, message: _vm.$t('Authentication.EnterThePassword')}] } ]),expression:"[\n\t\t\t\t\t\t\t\t\t'password',\n\t\t\t\t\t\t\t\t\t{ rules: [{ required: true, message: $t('Authentication.EnterThePassword')}] },\n\t\t\t\t\t\t\t\t]"}],attrs:{"type":"password","placeholder":_vm.$t('Authentication.EnterThePassword')}})],1),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","block":"","html-type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('Authentication.Login'))+" ")])],1)],1)],1)],1),_c('Clients',{attrs:{"clients":_vm.clientList}})],1),_c('a-col',{staticClass:"col-img",attrs:{"span":24,"md":12,"lg":12,"xl":12}},[_c('div',[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":"images/login-bk.svg","alt":"rocket"}})]),_c('h5',{staticClass:"text-white"},[_vm._v(_vm._s(_vm.instanceName)+" 携手国产化，引领信创未来，我们为你的软件制品保驾护航！")]),_c('p',{staticClass:"text-white"},[_vm._v(_vm._s(_vm.instanceName)+" Joining Hands in National Innovation, Pioneering the Future of Technology, We Safeguard Your Software Solutions!")])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }